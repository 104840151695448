import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import { useSpring } from "@react-spring/core";
import { a as web } from "@react-spring/web";
import { FiArrowDownRight } from "react-icons/fi";
import useBoop from "../lib/boop";

import HeroHullam from "../images/svg/hero-hullam.svg";

export const Home: React.FC = () => {
  const [style1, trigger1] = useBoop({ x: 5, y: 5 });
  const [style2, trigger2] = useBoop({ x: 5, y: 5 });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);
  const springConfig = { mass: 1, tension: 280, friction: 100 };

  const common = {
    borderRadius: "20px",
    overflow: "hidden",
    boxShadow:
      "0.4px 0.8px 1.7px rgba(0, 0, 0, 0.022),1px 2px 4px rgba(0, 0, 0, 0.032),1.9px 3.8px 7.5px rgba(0, 0, 0, 0.04),3.4px 6.7px 13.4px rgba(0, 0, 0, 0.048),6.3px 12.5px 25.1px rgba(0, 0, 0, 0.058),15px 30px 60px rgba(0, 0, 0, 0.08)",
    transform: "rotate(0deg) scale(0) translate(-50%,-50%)",
    opacity: 0,
    top: "50%",
    left: "50%",
    width: "0%",
    config: springConfig,
  };

  // chart springs
  const [ch1style, ch1api] = useSpring(() => ({ ...common }));
  const [ch2style, ch2api] = useSpring(() => ({ ...common }));

  // start animations when model is loaded
  useEffect(() => {
    if (!open) return;
    const animChart1 = setTimeout(() => {
      ch1api.start({
        opacity: 1,
        top: "45%",
        left: "15%",
        width: "30%",
        transform: "rotate(-30deg) scale(0.7) translate(-50%,-50%)",
      });
    }, 1000);
    const animChart2 = setTimeout(() => {
      ch2api.start({
        opacity: 1,
        top: "20%",
        left: "55%",
        width: "30%",
        transform: "rotate(20deg) scale(0.7) translate(-50%,-50%) ",
      });
    }, 2000);
    return () => {
      clearTimeout(animChart1);
      clearTimeout(animChart2);
    };
  }, [open]);

  return (
    <Layout
      allowTransparentHeader={true}
      variant="index"
      title="Compass Marketing & Consulting"
      description="A jó marketing egy megtérülő befektetés"
    >
      <Wrapper>
        <Title>
          Neked miben <span>segíthetünk</span>?
        </Title>
        <Link to="/marketing">
          <Marketing onMouseEnter={trigger1}>
            <HeroHullam
              css={css`
                pointer-events: none;
                position: absolute;
                top: 10%;
                left: -50%;
                width: 150%;
                height: 60%;
              `}
            />
            <div
              css={css`
                pointer-events: none;
                position: absolute;
                top: 25%;
                left: 0;
                width: 100%;
                height: 75%;
              `}
            >
              <StaticImage
                src="../images/hero-mac2.png"
                alt="Marketing"
                objectFit="contain"
              />
            </div>
            <ChartWrapper>
              <Chart style={ch1style}>
                <StaticImage src="../images/chart1.jpg" alt="chart1" />
              </Chart>
              <Chart style={ch2style}>
                <StaticImage src="../images/chart2.jpg" alt="chart2" />
              </Chart>
            </ChartWrapper>
            <LightOverlay />
            <Select>
              <web.div style={style1}>
                <FiArrowDownRight />
              </web.div>
              <h1>MARKETING</h1>
            </Select>
          </Marketing>
        </Link>
        <Link to="/weboldal">
          <Weboldal onMouseEnter={trigger2}>
            <StaticImage
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="../images/darkhero2.jpg"
              alt="Weboldal"
              objectPosition="center bottom"
              objectFit="cover"
            />
            <DarkOverlay />
            <Select>
              <web.div style={style2}>
                <FiArrowDownRight />
              </web.div>
              <h1>WEBOLDAL</h1>
            </Select>
          </Weboldal>
        </Link>
      </Wrapper>
    </Layout>
  );
};

export default Home;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;

  a:first-of-type {
    color: black;
  }
  a:last-of-type {
    color: white;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const Title = styled.h2`
  color: rgb(255, 255, 255);
  span {
    color: rgb(255, 255, 255);
  }
  mix-blend-mode: difference;
  position: absolute;
  z-index: 999;
  top: 120px;
  width: 100%;
  text-align: center;
  pointer-events: none;

  @media (max-width: 1000px) {
    color: black;
    mix-blend-mode: normal;
    top: 80px;
    span {
      color: var(--color-primary);
    }
  }
`;

const Marketing = styled.div`
  background: linear-gradient(180deg, #f0f0f0 0%, #f8f8f8 100%);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Weboldal = styled.div`
  background: black;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ChartWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;
const Chart = styled(web.div)`
  position: absolute;
  transform: translate(-50%, -50%);
`;

const DarkOverlay = styled.div`
  position: absolute;
  inset: 0;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.99) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  &:hover {
    opacity: 0.5;
  }
  transition: 0.5s;
`;
const LightOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.99) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  &:hover {
    opacity: 0;
  }
  transition: 0.5s;
`;

const Select = styled.div`
  z-index: 999;
  pointer-events: none;
  display: grid;
  grid-template-columns: 50px 1fr;
  svg,
  h1 {
    display: inline-block;
    margin: 0;
  }
  svg {
    width: 50px;
    height: 50px;
    color: var(--color-primary);
  }

  @media (max-width: 1000px) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
